/*
 * FOR NEMO
 * In NEMO, the layer templates include unneeded resources such as NEMO-HEADER
 * and NEMO-FOOTER as well as CSR Integrator. For the use inside one-layer
 * it is required to only get the content without all other resources. This can be accomplished
 * by loading the 'headless' variant of a template
 * */

export function headless(url: string): string {
  const hasHtmlReference = url.includes('.html');
  const headlessLink = url.includes('.headless.');
  const htmlIsSuffix = url.charAt(url.length - 1) === 'l';
  return hasHtmlReference && htmlIsSuffix && !headlessLink
    ? url.replace('.html', '.headless.html')
    : url;
}
