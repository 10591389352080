import React from 'react';
import styled from 'styled-components';
import {
  getClassNamesForLinks,
  getIdNameForLink,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { Link } from '../../types/audi-footer-response.types';

type LegalLinksProps = {
  countryCode?: string;
  links?: Link[];
  openLayer: React.MouseEventHandler<HTMLAnchorElement>;
  useOneLayer?: boolean;
};

const LegalLinksStyledWrapper = styled.div`
  flex-grow: 1;
  margin-right: var(--one-footer-space-xl);

  & > ul {
    display: inline-flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0 0 calc(var(--one-footer-space-l) - var(--one-footer-space-s));
    padding: 0;
  }

  & > ul > li:not(:last-child) {
    padding-right: var(--one-footer-space-l);
  }

  & > ul > li > a {
    display: inline-block;
    margin-bottom: var(--one-footer-space-s);
  }
`;

const LegalLinks: React.FC<LegalLinksProps> = ({ countryCode, links, openLayer, useOneLayer }) => {
  return (
    <LegalLinksStyledWrapper>
      <ul>
        {links?.map((link) => {
          return (
            <li key={`${link.Target}${link.Text}${link.Url}`}>
              <a
                className={getClassNamesForLinks(link, countryCode, useOneLayer)}
                /* nemo specific cookie modal link click */
                /* eslint-disable-next-line no-nested-ternary */
                {...(!!link.Type && link.Type === 'cookie-settings-layer' && countryCode !== 'US'
                  ? { onClick: onCookieModalLinkClick }
                  : link.Target === '_layer' && useOneLayer
                    ? { onClick: openLayer }
                    : {})}
                href={link.Url}
                target={getLinkTarget(link, useOneLayer)}
                {...(getIdNameForLink(link) ? { id: getIdNameForLink(link) } : {})}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </LegalLinksStyledWrapper>
  );
};

export default LegalLinks;
