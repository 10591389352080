/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { ThemeProvider, audiDarkTheme } from '@audi/audi-ui-react';
import I18nContextComponent from '@oneaudi/i18n-context';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { AudiMarketContextServiceV2 } from '@oneaudi/market-context-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { AudiFooterResponse, ContentHeadless } from './types/audi-footer-response.types';
import FeatureApp from './FeatureApp';
import { getData } from './services/api';
import type { PartnerId } from './types/partner-id';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'layer-manager': LayerManagerV27 | undefined;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 'audi-market-context-service': AudiMarketContextServiceV2 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 's2:logger': Logger;
  readonly 'audi-content-service': ContentServiceV1;
}

// Type to work with the microkernel that maybe is available globaly
declare global {
  interface Window {
    microkernel: {
      stateRegistry: {
        subscribeToStore: (name: string, callback: (state: PartnerId) => void) => void;
        unsubscribeFromStore: (name: string, callback: (state: PartnerId) => void) => void;
      };
    };
  }
}

export type AudiFooterConfig = {
  api?: string;
  enablePartnerIdReplacement?: boolean;
  hideSocialMedia?: boolean;
  enableMinimalFooter?: boolean;
  isDemo?: boolean;
};

type FeatureHubAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  AudiFooterConfig
>;

const featureAppDefinition: FeatureHubAppDefinitionType = {
  create: ({ featureServices, config, featureAppId }) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const asyncSsrManager: AsyncSsrManagerV1 | undefined = featureServices['s2:async-ssr-manager'];
    const serializedStateManager: SerializedStateManagerV1 =
      featureServices['s2:serialized-state-manager'];
    const logger: Logger = featureServices['s2:logger'];
    const { countryCode } = featureServices['gfa:locale-service'];
    const i18nData: I18NServiceV1 = featureServices['dbad:audi-i18n-service'];
    const marketContext: AudiMarketContextServiceV2 | undefined =
      featureServices['audi-market-context-service'];
    const referenceServiceScopeManager = featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
    const layerManager = featureServices['layer-manager'];
    const localeService = featureServices['locale-service'];
    const contentService = featureServices['audi-content-service'];
    const contentHeadless = contentService.getContent() as ContentHeadless;

    const referenceServiceManager = referenceServiceScopeManager
      ? referenceServiceScopeManager.getDefaultScopeRefService()
      : undefined;

    const isDemo = config?.isDemo;

    try {
      const api = config?.api;
      const enablePartnerIdReplacement = config?.enablePartnerIdReplacement;
      const hideSocialMedia = config?.hideSocialMedia;
      const enableMinimalFooter = config?.enableMinimalFooter;

      let audiFooterError: Error;
      let audiFooterResponse: AudiFooterResponse | undefined;

      if (contentHeadless?.__type !== 'aem-headless') {
        if (typeof asyncSsrManager !== 'undefined') {
          // Ignore callback from Jest coverage because it is not invoked here.
          // Also, it is not possible to move it outside the `create`
          // factory function since it relies on `audiFooterResponse`.
          /* istanbul ignore next */
          if (typeof serializedStateManager !== 'undefined') {
            serializedStateManager.register(() => JSON.stringify(audiFooterResponse));
          }
          asyncSsrManager.scheduleRerender(
            (async (): Promise<void> => {
              try {
                audiFooterResponse = await getData(api);
              } catch (error: any) {
                audiFooterError = error as Error;
                logger.error(`[${featureAppId}] Could not fetch data. ${error}`);
              }
            })(),
          );
        } else {
          const serializedAudiFooterData = serializedStateManager?.getSerializedState();
          if (typeof serializedAudiFooterData !== 'undefined') {
            try {
              audiFooterResponse = JSON.parse(serializedAudiFooterData) as AudiFooterResponse;
            } catch (error: any) {
              audiFooterError = error as Error;
              logger.error(`[${featureAppId}] Bad parsing of serialized state. ${error}`);
            }
          }
        }
      }

      return {
        render: (): React.ReactNode => (
          <ThemeProvider theme={audiDarkTheme}>
            <I18nContextComponent
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              featureServices={featureServices}
              i18nData={i18nData}
              scopes={['nemo.common', 'fa.audi-basket']}
            >
              <FeatureApp
                api={api}
                contentHeadless={contentHeadless}
                localeService={localeService}
                countryCode={countryCode}
                data={audiFooterResponse}
                enablePartnerIdReplacement={enablePartnerIdReplacement}
                error={audiFooterError}
                featureAppId={featureAppId}
                hideSocialMedia={hideSocialMedia}
                referenceServiceManager={referenceServiceManager}
                marketContext={marketContext}
                layerManager={layerManager}
                enableMinimalFooter={enableMinimalFooter}
                isDemo={isDemo || false}
              />
            </I18nContextComponent>
          </ThemeProvider>
        ),
      };
    } catch (error) {
      const audiFooterError: Error = error as Error;
      logger.error(audiFooterError);
      return {
        render(): React.ReactNode {
          return (
            <ThemeProvider theme={audiDarkTheme}>
              <I18nContextComponent
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                featureServices={featureServices}
                i18nData={i18nData}
                scopes={[]}
              >
                <FeatureApp
                  api=""
                  countryCode={countryCode}
                  data={undefined}
                  error={audiFooterError}
                  featureAppId={featureAppId}
                  referenceServiceManager={referenceServiceManager}
                  marketContext={marketContext}
                  layerManager={layerManager}
                  isDemo={isDemo || false}
                />
              </I18nContextComponent>
            </ThemeProvider>
          );
        },
      };
    }
  },
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'layer-manager': '^2.5.0',
      'locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.5.0',
      'styled-components': '^5.3.11',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-footnote-reference-service': '3.0.0',
      'audi-market-context-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },
};

export default featureAppDefinition;
