import BezierEasing from 'bezier-easing';
import React from 'react';
import styled from 'styled-components';

type ToTopButtonProps = {
  label?: string;
};

export const timingXXL = 660;
export const averagePageHeight = 4000;
export const easeOut = BezierEasing(0.21, 0.55, 0.43, 1);

const ToTopStyledLink = styled.a<{
  href: string;
  onClick: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
}>`
  align-items: center;
  display: inline-flex;

  & > span {
    font-family: var(${(t: ThemeProps): string => t.theme.responsive.typography.copy2.fontFamily});
    font-size: var(${(t: ThemeProps): string => t.theme.responsive.typography.copy2.fontSize});
    font-stretch: var(
      ${(t: ThemeProps): string => t.theme.responsive.typography.copy2.fontStretch}
    );
    line-height: var(${(t: ThemeProps): string => t.theme.responsive.typography.copy2.lineHeight});
    margin-right: var(--one-footer-space-s);
  }
  & > svg {
    transform: rotate(180deg);
  }
`;

const ToTopLink: React.FC<ToTopButtonProps> = ({ label }) => {
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    animate();
  };

  return (
    <ToTopStyledLink aria-label={label} href="#" onClick={onClick}>
      <span>{label}</span>
      <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10l5.5 5.5L18 10" fill="none" stroke="currentColor" />
      </svg>
    </ToTopStyledLink>
  );
};

export function animate(): void {
  if (typeof window === 'undefined') {
    return;
  }

  const body = document.querySelector('body');
  const bodyHeight = body?.scrollHeight;
  const duration = bodyHeight ? (bodyHeight * timingXXL) / averagePageHeight : undefined;
  const startPosition = window.scrollY;
  const startTime: number = Date.now();

  function render(): void {
    const timeNormalized: number | undefined = duration
      ? (Date.now() - startTime) / duration
      : undefined;
    const easeOutTime = timeNormalized ? easeOut(timeNormalized) : 0;
    window.scrollTo(0, startPosition - startPosition * easeOutTime);
    if (timeNormalized && timeNormalized <= 1) {
      requestAnimationFrame(render);
    }
  }

  setTimeout(render, 1);
}

export default ToTopLink;
