import { AudiFooterResponse } from '../types/audi-footer-response.types';
import { PartnerId } from '../types/partner-id';

export function replacePartnerId(
  componentData: AudiFooterResponse,
  partnerId?: PartnerId,
): AudiFooterResponse {
  let replacementComponentData = JSON.stringify(componentData);
  replacementComponentData = replacementComponentData
    .replace(/\{kvpsid\}|%7Bkvpsid%7D/gi, partnerId?.kvpsid || '')
    .replace(/\{kvpsSyncId\}|%7BkvpsSyncId%7D/gi, partnerId?.kvpsSyncId || '');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return JSON.parse(replacementComponentData);
}
