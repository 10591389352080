/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import type { AudiFooterProps } from './components/footer/audi-feature-app-footer';
import AudiFooter from './components/footer/audi-feature-app-footer';

const FeatureApp: React.FC<AudiFooterProps> = ({
  api,
  contentHeadless,
  data,
  countryCode,
  enablePartnerIdReplacement,
  hideSocialMedia,
  error,
  featureAppId,
  referenceServiceManager,
  layerManager,
  marketContext,
  enableMinimalFooter,
  localeService,
  isDemo,
}) => {
  return (
    <AudiFooter
      api={api}
      contentHeadless={contentHeadless}
      countryCode={countryCode}
      data={data}
      enablePartnerIdReplacement={enablePartnerIdReplacement}
      error={error}
      featureAppId={featureAppId}
      hideSocialMedia={hideSocialMedia}
      referenceServiceManager={referenceServiceManager}
      marketContext={marketContext}
      layerManager={layerManager}
      enableMinimalFooter={enableMinimalFooter}
      localeService={localeService}
      isDemo={isDemo}
    />
  );
};

export default FeatureApp;
