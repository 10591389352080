// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fetch from 'node-fetch';
import { AudiFooterResponse } from '../types/audi-footer-response.types';

export async function getData(url: string | undefined): Promise<AudiFooterResponse | undefined> {
  if (typeof url !== 'undefined') {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    const response = await fetch(url);
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    return (await response.json()) as AudiFooterResponse;
  }
  return undefined;
}
